import {mapMutations, mapGetters, mapActions} from "vuex";
import {dynamicHead} from "../../mixins/dynamic-head";


export default {
    name: "prices",

    data() {
        return {
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: 'projects', title: this.$t('header.price'), slug: this.$t('header.price')}
            ],
        }
    },
    watch: {},
    computed: {
        ...mapGetters({
            searchList: 'catalog/searchList',
            searchListLoading: 'catalog/searchListLoading',
            pageContent: 'setting/pageContent',
            globalRequestsFlag: 'system/globalRequestsFlag',
            pricingLists: 'articles/pricingLists'
        }),
    },
    mixins: [dynamicHead],
    created() {
        this.getPageContent('pricing').then(() => {
            this.setPageItem(this.pageContent)
            this.getPricingLists()
        })
        this.setRequestFlag(true)
    },
    mounted() {
        this.getPageContent('pricing').then(() => {
            this.setPageItem(this.pageContent)
            document.title = `${this.pageContent.title}`
        })

    },
    methods: {
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        ...mapActions({
            getPageContent: 'setting/GET_PAGE_CONTENT',
            getPricingLists: 'articles/GET_PRICING_LIST'
        }),
        download(item) {
            window.open(item.filePath, '_blank');
        },
    }
}
